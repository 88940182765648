class Slider {
    private slides: HTMLElement[];
    private dots: HTMLElement[];
    private currentSlide: number = 0;
    private slideInterval: number = 0;
    private readonly STORAGE_KEY = 'slider_state';

    constructor() {
        this.slides = Array.from(document.querySelectorAll('.slide'));
        this.dots = Array.from(document.querySelectorAll('.dot'));
        
        // 恢复保存的状态
        this.restoreState();
        
        // 绑定按钮事件
        document.querySelector('.prev')?.addEventListener('click', () => this.prevSlide());
        document.querySelector('.next')?.addEventListener('click', () => this.nextSlide());
        
        // 绑定圆点事件
        this.dots.forEach((dot, index) => {
            dot.addEventListener('click', () => this.goToSlide(index));
        });

        // 绑定缩放事件
        this.slides.forEach(slide => {
            slide.addEventListener('click', () => this.handleSlideClick(slide));
        });

        // 自动播放
        this.startAutoPlay();

        // 页面关闭前保存状态
        window.addEventListener('beforeunload', () => this.saveState());
    }

    private handleSlideClick(slide: HTMLElement): void {
        // 只有当前活动的幻灯片才能缩放
        if (!slide.classList.contains('active')) {
            return;
        }
        
        // 切换缩放状态
        const isZoomed = slide.classList.contains('zoomed');
        
        // 移除所有幻灯片的缩放状态
        this.slides.forEach(s => s.classList.remove('zoomed'));
        
        if (!isZoomed) {
            // 如果之前未缩放，则添加缩放
            slide.classList.add('zoomed');
            // 暂停自动播放
            clearInterval(this.slideInterval);
        } else {
            // 如果之前已缩放，则恢复自动播放
            this.startAutoPlay();
        }
        
        // 保存状态
        this.saveState();
    }

    private saveState(): void {
        const state = {
            currentSlide: this.currentSlide,
            zoomedSlideIndex: this.slides.findIndex(slide => slide.classList.contains('zoomed'))
        };
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(state));
    }

    private restoreState(): void {
        try {
            const savedState = localStorage.getItem(this.STORAGE_KEY);
            if (savedState) {
                const state = JSON.parse(savedState);
                
                // 恢复当前幻灯片
                this.goToSlide(state.currentSlide);
                
                // 恢复缩放状态
                if (state.zoomedSlideIndex >= 0) {
                    this.slides[state.zoomedSlideIndex].classList.add('zoomed');
                    clearInterval(this.slideInterval); // 如果有缩放的幻灯片，暂停自动播放
                }
            }
        } catch (error) {
            console.warn('Failed to restore slider state:', error);
        }
    }

    private goToSlide(index: number): void {
        // 如果有幻灯片处于缩放状态，不切换
        if (this.slides.some(slide => slide.classList.contains('zoomed'))) {
            return;
        }

        // 移除当前活动状态
        this.slides[this.currentSlide].classList.remove('active');
        this.dots[this.currentSlide].classList.remove('active');
        
        // 设置新的活动状态
        this.currentSlide = index;
        this.slides[this.currentSlide].classList.add('active');
        this.dots[this.currentSlide].classList.add('active');
        
        // 保存状态
        this.saveState();
    }

    private nextSlide(): void {
        // 如果有幻灯片处于缩放状态，不切换
        if (this.slides.some(slide => slide.classList.contains('zoomed'))) {
            return;
        }
        const next = (this.currentSlide + 1) % this.slides.length;
        this.goToSlide(next);
    }

    private prevSlide(): void {
        // 如果有幻灯片处于缩放状态，不切换
        if (this.slides.some(slide => slide.classList.contains('zoomed'))) {
            return;
        }
        const prev = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
        this.goToSlide(prev);
    }

    private startAutoPlay(): void {
        // 清除现有的定时器
        if (this.slideInterval) {
            clearInterval(this.slideInterval);
        }

        // 如果有幻灯片处于缩放状态，不启动自动播放
        if (this.slides.some(slide => slide.classList.contains('zoomed'))) {
            return;
        }

        this.slideInterval = setInterval(() => {
            this.nextSlide();
        }, 5000); // 每5秒切换一次

        // 鼠标悬停时暂停自动播放
        const slider = document.querySelector('.slider');
        slider?.addEventListener('mouseenter', () => {
            clearInterval(this.slideInterval);
        });

        slider?.addEventListener('mouseleave', () => {
            this.startAutoPlay();
        });
    }
}

// 页面加载完成后初始化滑块
document.addEventListener('DOMContentLoaded', () => {
    new Slider();
});
